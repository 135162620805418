import "./index.scss";
import "react-toastify/dist/ReactToastify.min.css";

import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";

import AppRouter from "./AppRouter";
import Navbar from "components-layout/Navbar";

import { AppContextProvider } from "contexts/AppContext";
import ProfileScraping from "../components/ProfileScraping/ProfileScraping";
import useProfileScraping from "../components/ProfileScraping/useProfileScraping";
import { CiSearch } from "react-icons/ci";

function AppWrapper() {
  const { handleInputChange, handleSearch, searchTerm } = useProfileScraping();
  return (
    <>
      <AppContextProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <Navbar />
        {/* <div className="flex justify-start items-center rounded-lg bg-gray-100 w-80 relative left-60 mt-5">
            <button onClick={handleSearch} className="m-2">
              <CiSearch />
            </button>
            <input
              type="text"
              placeholder="Search by date, message..."
              value={searchTerm}
              onChange={handleInputChange}
              className="bg-gray-100 rounded-lg w-80 p-1 border-none outline-none"
            />
          </div> */}
        <ProfileScraping />

        {/*<div className={"mainWrapper"}>
          <AppRouter />
  </div>*/}
      </AppContextProvider>
    </>
  );
}

function App() {
  return (
    <ErrorBoundary
      fallback={
        <>
          <div>Fatal Error</div>
        </>
      }
    >
      <AppContextProvider>
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </AppContextProvider>
    </ErrorBoundary>
  );
}

export default App;

import { getRequest } from "../api/_setup";

export function fetchLogsForRunStartTime(platform, runStartTime) {
  return getRequest(
    `/logs/scraping/coding-profiles?platform=${platform}&runStartTime=${runStartTime}`
  );
}

export function fetchRunStartTimes(platform) {
  return getRequest(`/logs/scraping/coding-profiles/run-start-times?platform=${platform}`);
}

import { useState } from "react";
import style from "./Sidebar.module.scss";
import clsx from "clsx";
import { format } from "date-fns";

const DateFormat = ({ timestamp }) => {
  const formattedDate = format(new Date(timestamp), "MMM dd, hh:mm a");
  return <p>{formattedDate}</p>;
};

const Sidebar = ({ handleContentClick, data, selectedContent }) => {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={clsx(
        `${open ? "w-[15%] min-w-[15%]" : "w-0"} h-[100%] duration-300 `,
        style.wrapper
      )}
    >
      <div className={`cursor-pointer top-4 ${!open}`}>
        <ul className={style.itemsList}>
          {data?.map((timestamp) => (
            <li
              key={timestamp}
              className={clsx(
                timestamp === selectedContent && style.selected,
                `text-gray-400 text-sm flex items-center cursor-pointer text-nowrap mb-3`
              )}
              onClick={() => handleContentClick(timestamp)}
            >
              <DateFormat timestamp={timestamp} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

import clsx from "clsx";
import style from "./Navbar.module.scss";

export default function Navbar() {
  return (
    <div className={clsx(style.wrapper, "withPadding")}>
      <nav>
        <ul className="flex justify-start items-center list-none">
          <li>CCC Logs</li>
        </ul>
      </nav>
    </div>
  );
}

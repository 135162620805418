import React from "react";
import style from "./LogMsgTable.module.scss";

import clsx from "clsx";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { CiCircleInfo } from "react-icons/ci";

import ReactJson from "react-json-view";

import useProfileScraping from "../../components/ProfileScraping/useProfileScraping";

const LogMsgTable = ({ logs }) => {
  const { handleToggle } = useProfileScraping();

  return (
    <div className={clsx("px-5", style.wrapper)}>
      <div className={style.inner}>
        <div className="text-lg">
          <p className="mb-[1.5rem]">Id</p>
        </div>

        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr className="">
              <th className="">Message</th>
              <th className="">Time</th>
              <th className="">Level</th>
              <th className="">Platform</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="">
            {logs?.map((log, index) => {
              const { timestamp, level, message, platform, isOpen } = log;
              return (
                <>
                  <tr
                    key={index}
                    className={clsx(message === "Started Msg Processing" && style.msgStart)}
                  >
                    <td>{message}</td>
                    <td>{format(new Date(timestamp), "MMM dd, hh:mm:ss a")}</td>
                    <td>{level.toUpperCase()}</td>
                    <td>{platform}</td>
                    <td className={`m-2 ${isOpen && "rotate-180 "}`}>
                      <Link onClick={() => handleToggle(index, logs)}>
                        <CiCircleInfo />
                      </Link>
                    </td>
                  </tr>
                  {isOpen && (
                    <div>
                      <ReactJson src={log} theme="light" />
                    </div>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LogMsgTable;

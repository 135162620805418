import React from "react";
import LogMsgTable from "../../components-shared/LogMsgTable/LogMsgTable";

const LogViewer = ({ logs }) => {
  return (
    <div className="pb-10 w-[85%]">
      <>{!!logs.length ? <LogMsgTable logs={logs} /> : <div>Please select time</div>}</>
    </div>
  );
};

export default LogViewer;

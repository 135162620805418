import { useEffect, useState } from "react";
import { fetchLogsForRunStartTime, fetchRunStartTimes } from "../../api/getData";

const useProfileScraping = () => {
  const [selectedContent, setSelectedContent] = useState(null);

  const [runStartTimes, setRunStartTimes] = useState([]);

  const [logs, setLogs] = useState({});
  const [activeLogs, setActiveLogs] = useState([]);

  const [open, setOpen] = useState(false);
  const [dataSet, setDataSet] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    console.log("Searching for:", searchTerm);
  };

  const handleContentClick = (content) => {
    setSelectedContent(content);
    getData(content);
  };

  const handleToggle = (index, dataSet) => {
    const updatedData = [...dataSet];
    updatedData[index].isOpen = open;

    setDataSet(updatedData);
    setOpen(!open);
  };

  function getData(runStartTime) {
    if (logs[runStartTime]) {
      setActiveLogs(logs[runStartTime]);
    } else {
      fetchLogsForRunStartTime("codechef", runStartTime)
        .then((res) => {
          setLogs((prev) => ({ ...prev, [runStartTime]: res.data.data }));
          setActiveLogs(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  }

  function getRunStartTimes() {
    fetchRunStartTimes("codechef")
      .then((res) => {
        setRunStartTimes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
    getRunStartTimes();
  }, []);

  return {
    selectedContent,
    runStartTimes,
    handleContentClick,
    dataSet,
    handleToggle,
    handleInputChange,
    handleSearch,
    searchTerm,
    activeLogs,
  };
};

export default useProfileScraping;

import React from "react";
import Sidebar from "../../components-layout/Sidebar/Sidebar";
import useProfileScraping from "./useProfileScraping";
import LogViewer from "./LogViewer";

const ProfileScraping = () => {
  const { selectedContent, runStartTimes, handleContentClick, activeLogs } = useProfileScraping();

  return (
    <div className="flex mt-5 relative w-[100%] withPadding">
      <Sidebar
        handleContentClick={handleContentClick}
        data={runStartTimes}
        selectedContent={selectedContent}
      />
      <LogViewer selectedContent={selectedContent} logs={activeLogs} />
    </div>
  );
};

export default ProfileScraping;
